<template>
    <div class="mx-auto headSpacingLanding" style="">
        <!-- Hero banner-->
        <div>
            <b-jumbotron class="bgGenJumbo white mb-0" style="border-radius:0px;">
                <template #header><span class="fw600 jumboHeader">Welcome to the Next Generation</span></template>

                <template #lead>
                    <span class="textWarningCustom fs25 bold jumboSmaller"><i>Better insurance starts here</i></span>
                </template>
            </b-jumbotron>
        </div>
        
        
        <!--
        <div class="col heroImage" style="height: 700px; overflow: hidden">
            <b-col class="h1 genBlue mt-5 pt-5">Welcome to the Next Generation</b-col>
        </div>

        <div class="px-0 mx-0 w-75 mx-auto h-100 heroText text-white text-left">
            <b-col>
                <b-col class="h1">Welcome to the Next Generation</b-col>
                <b-col class="h2 pt-2">Better insurance starts here</b-col>
            </b-col>
        </div>-->

        <!--policies-->
        <div class="policies py-5" style="z-index: 99999">
            <div class="policiesHeader text-center pb-md-5 mb-5 pt-4">
                <p class="fw600 policiesTitle pb-4">Our top policies</p>
                <p class="titleDesc mx-auto col-12 col-md-8">
                    No small business is the same, but many companies share the same risks. These key policies can protect you from common
                    accidents, lawsuits, and damages. Our expert agents can offer advice based on your unique needs. Check out some of the
                    top policies we offer.
                </p>
            </div>
            <!-- policy cards-->
            <b-container>
                <!-- Card rows-->
                <div class="row cardRow px-0 mx-0">
                    <!--Card Item-->
                    <div class="cardItem col-12 col-md-4 pb-4 mobileNoPaddingX">
                        <!--Inner card-->
                        <div class="col text-center cardBorder px-0 mx-0">
                            <div class="pt-4">
                                <b-icon-cone-striped class="cardLogo" />
                            </div>
                            <p class="fw700 cardTitle pt-4">General liability insurance</p>
                            <p class="cardDesc mx-auto pt-3 pb-5">
                                This policy covers common business risks including bodily injuries, customer property damage, and
                                advertising injuries. It's required for most commercial leases.
                            </p>
                            <p class="bestFor fw700 pt-4">BEST FOR</p>
                            <div class="bestReasons pb-5">
                                <div class="col">Slip-and-fall accidents</div>
                                <div class="col">Damaged customer property</div>
                                <div class="col">Libel or slander lawsuits</div>
                            </div>
                            <b-row class="mx-auto pb-5" align-content="center">
                                <b-col>
                                    <router-link :to="{ name: 'contact' }" class="linkQuote buttonSolid px-4 py-2 pointer">Get Quoted</router-link>
                                </b-col>
                            </b-row>
                        </div>
                    </div>

                    <!--Card Item-->
                    <div class="cardItem col-12 col-md-4 pb-4 pb-md-0 mobileNoPaddingX">
                        <!--Inner card-->
                        <div class="col text-center cardBorder px-0 mx-0">
                            <div class="pt-4">
                                <b-icon-shop class="cardLogo" />
                            </div>
                            <p class="fw700 cardTitle pt-4">Business owner's policy</p>
                            <p class="cardDesc mx-auto pt-3 pb-5">
                                A business owner's policy, or BOP, is a cost-effective way for small business owners to buy general
                                liability coverage and commercial property insurance.
                            </p>
                            <p class="bestFor fw700 pt-4">BEST FOR</p>
                            <div class="bestReasons pb-5">
                                <div class="col">Customer injuries</div>
                                <div class="col">Damaged business property</div>
                                <div class="col">Business interruption incidents</div>
                            </div>
                            <div class="row pb-5 justify-content-center cardButtonRow mx-auto">
                                <div class="col">
                                    <router-link :to="{ name: 'contact' }" class="linkQuote buttonSolid px-4 py-2 pointer">Get Quoted</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Card Item-->
                    <div class="cardItem col-12 col-md-4 pb-4 pb-md-0 mobileNoPaddingX">
                        <!--Inner card-->
                        <div class="col text-center cardBorder px-0 mx-0">
                            <div class="pt-4">
                                <b-icon-receipt-cutoff class="cardLogo" />
                            </div>
                            <p class="fw700 cardTitle pt-4">Professional liability insurance</p>
                            <p class="cardDesc mx-auto pt-3 pb-5">
                                This policy, also called errors and omissions insurance (E&O), can help cover legal expenses if a business
                                is sued for a mistake, missed deadline, etc.
                            </p>
                            <p class="bestFor fw700 pt-4">BEST FOR</p>
                            <div class="bestReasons pb-5">
                                <div class="col">Professional negligence lawsuits</div>
                                <div class="col">Project scope disputes</div>
                                <div class="col">Work mistakes or oversights</div>
                            </div>
                            <div class="row pb-5 justify-content-center cardButtonRow mx-auto">
                                <div class="col">
                                    <router-link :to="{ name: 'contact' }" class="linkQuote buttonSolid px-4 py-2 pointer">Get Quoted</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Card Item-->
                    <div class="cardItem col-12 col-md-4 pb-4 pb-md-0 mobileNoPaddingX">
                        <!--Inner card-->
                        <div class="col text-center cardBorder px-0 mx-0">
                            <div class="pt-4">
                                <i class="bi bi-building cardLogo"></i>
                            </div>
                            <p class="fw700 cardTitle pt-4">
                                Commercial property
                            </p>
                            <p class="cardDesc mx-auto pt-3 pb-5">
                                This policy covers a business’s physical structure and its contents, such as inventory, equipment, and
                                furniture. It protects against theft, fire, and other losses and damages.
                            </p>
                            <p class="bestFor fw700 pt-4">BEST FOR</p>
                            <div class="bestReasons pb-5">
                                <div class="col">Fire</div>
                                <div class="col">Theft or vandalism</div>
                                <div class="col">Windstorms</div>
                            </div>
                            <div class="row pb-5 justify-content-center cardButtonRow mx-auto">
                                <div class="col">
                                    <router-link :to="{ name: 'contact' }" class="linkQuote buttonSolid px-4 py-2 pointer">Get Quoted</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Card Item-->
                    <div class="cardItem col-12 col-md-4 pb-4 pb-md-0 mobileNoPaddingX">
                        <!--Inner card-->
                        <div class="col text-center cardBorder px-0 mx-0">
                            <div class="pt-4">
                                <i class="bi bi-bandaid cardLogo"></i>
                            </div>
                            <p class="fw700 cardTitle pt-4">
                                Workers' compensation
                            </p>
                            <p class="cardDesc mx-auto pt-3 pb-5">
                                Workers’ compensation insurance is required in almost every state for businesses that have employees. It can
                                cover medical costs for work-related injuries.
                            </p>
                            <p class="bestFor fw700 pt-4">BEST FOR</p>
                            <div class="bestReasons pb-5">
                                <div class="col">Employee medical expenses</div>
                                <div class="col">Partial missed wages</div>
                                <div class="col">Lawsuits over employee injuries</div>
                            </div>
                            <div class="row pb-5 justify-content-center cardButtonRow mx-auto">
                                <div class="col">
                                    <router-link :to="{ name: 'contact' }" class="linkQuote buttonSolid px-4 py-2 pointer">Get Quoted</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Card Item-->
                    <div class="cardItem col-12 col-md-4 flex-fill mobileNoPaddingX">
                        <!--Inner card-->
                        <div class="col text-center cardBorder px-0 mx-0 h-100">
                            <div class="pt-4">
                                <i class="bi bi-pc-display-horizontal cardLogo"></i>
                            </div>
                            <p class="fw700 cardTitle pt-4">Cyber liability</p>
                            <p class="cardDesc mx-auto pt-3 pb-5">
                                This policy helps small businesses survive data breaches and cyberattacks. It can pay for recovery expenses,
                                including the cost of notifying customers affected by a breach.
                            </p>

                            <p class="bestFor fw700 pt-4">BEST FOR</p>
                            <div class="bestReasons pb-5">
                                <div class="col">Data breach lawsuits</div>
                                <div class="col">Customer notification expenses</div>
                                <div class="col">Fraud monitoring costs</div>
                            </div>
                            <div class="row pb-5 justify-content-center cardButtonRow mx-auto">
                                <div class="col">
                                    <router-link :to="{ name: 'contact' }" class="linkQuote buttonSolid px-4 py-2 pointer">Get Quoted</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-container>
        </div>

        <!--Why Next Gen-->
        <div class="whyUs py-5 my-5">
            <div class="coveragesTitle text-center">
                <p class="pb-4 pt-1 bubbleHeaderTitle fw600">Why Next Gen?</p>
                <p class="titleDesc col-12 col-md-7 mx-auto pt-3 pb-2">
                    We are the #1 agency in South Carolina for personal and small business insurance. We help business owners compare quotes
                    from top-rated providers, buy or change policies, and renew existing ones.
                </p>
            </div>

            <div class="row col-12 col-md-8 mx-auto pt-5 mt-4 pb-3">
                <div class="col text-center pb-4 pb-md-0">
                    <div class="col whyUsNumber fw600 pb-0 pb-md-4">30+</div>
                    <div class="col whyUsDesc fw600">Years in business</div>
                </div>
                <div class="col text-center pb-4 pb-md-0">
                    <div class="col whyUsNumber fw600 pb-0 pb-md-4">5,000+</div>
                    <div class="col whyUsDesc fw600">Small businesses served</div>
                </div>
                <div class="col text-center pb-md-0">
                    <div class="col whyUsNumber fw600 pb-0 pb-md-4">12,000+</div>
                    <div class="col whyUsDesc fw600">Policies written</div>
                </div>
            </div>
        </div>

        <!--Coverages -->
        <div class="jobCoverage">
            <div class="coveragesTitle text-center pt-5 my-5">
                <p class="pt-5 pb-3 bubbleHeaderTitle fw600">
                    Whatever you do, <br />
                    we've got you covered
                </p>
            </div>
            <!--Icons and info-->
            <div class="bubbleWrapper py-4">
                <div class="bubbleContainer container">
                    <div class="row">
                    
                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-columns-gap floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Building design</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-wind floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Cleaning services</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-building floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Construction & contracting</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-ear floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Consulting</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-piggy-bank floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">
                                    Finance & accounting
                                </p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-cup-straw floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Food & beverage</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-heart-pulse floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Healthcare professionals</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-cpu floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">IT / technology</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-brightness-high floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Landscaping</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-tools floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Installation professionals</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-broadcast-pin floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Media & advertising</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-balloon-heart floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Nonprofits</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-briefcase floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Insurance professionals</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-people floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Personal care</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-camera-reels floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Photo & video</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-printer floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Printing & copying</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-award floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Professional services</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-house-door floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Real estate</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-cash-coin floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Retail</p>
                            </div>
                        </div>

                        <div class="bubbleItem col-6 col-md-auto">
                            <div class="bubbleIcon text-center">
                                <i class="bi bi-activity floating"></i>
                            </div>

                            <div class="bubbleTitle text-center pb-5 pt-3">
                                <p class="fs-20">Sports & fitness</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Carousel -->
        <div class="text-center">
            <p class="fw600 carouselHeader mt-5">What our customers are saying</p>
            <div class="carouselIcon">
                <i class="bi bi-quote"></i>
            </div>
            <carousel class="carouselWrapper pb-5 text-center" :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="10000" :speed="2000">
                <slide>
                    <div class="carouselQuote my-auto col-12 col-md-9 mx-auto pt-1">
                        When the pandemic hit, my business almost went under. I called Veronica and was told to come into her
                        office, within a couple minutes we reviewed my options and I left with an updated policy. It truely couldn't
                        have been easier.
                    </div>
                    <div class="carouselAuthor fw700 pt-4">
                        Bryan R. <br />
                        Reasturant Owner
                    </div>
                </slide>
                <slide>
                    <div class="carouselQuote my-auto col-12 col-md-9 mx-auto pt-1">
                        I was out of town and I couldn't pay over the phone, my husband only speaks spanish but luckily the agent who was there Veronica does too! He said she was very professional and was in and out within 5 minutes.
                    </div>
                    <div class="carouselAuthor fw700 pt-4">
                        Sophia G. <br />
                        Personal Driver
                    </div>
                </slide>
                <slide>
                    <div class="carouselQuote my-auto col-12 col-md-9 mx-auto pt-1">
                        Veronica is a breath of fresh air. I loved every second dealing with her. And, yes, I'm talking about buying insurance. Most people don't love that, but Veronica made the experience exceptional. She is a rock star!
                    </div>
                    <div class="carouselAuthor fw700 pt-4">
                        Lauren D. <br />
                        Small Business Owner
                    </div>
                </slide>
                <slide>
                    <div class="carouselQuote my-auto col-12 col-md-9 mx-auto pt-1">
                        Her knowledge on coverages was honestly surprising, I have a tech startup and wanted to make sure my users data would be covered in the event anything happened. I asked a bunch of questions but not a single on did she not have an answer to. Talking to someone who knows everything they can about what they do without trying to upsell you was a nice surprise.
                    </div>
                    <div class="carouselAuthor fw700 pt-4">
                        Mark S. <br />
                        Tech Startup
                    </div>
                </slide>
            </carousel>
        </div>

        <!-- Get quotes today-->
        <div class="todayWrapper pt-4 pb-5">
            <div class="todayHeader text-center py-5">
                <p class="py-5 bubbleHeaderTitle fw600 mb-0">A personalized auto quote is just a few clicks away</p>
                <div class="startQuote py-2 mt-2">
                    <router-link to="auto" class="white quoteButton pointer py-3 px-5 align-middle">Get started</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/scss/bootstrap-icons.css'

export default {
    name: "Landing",
};
</script>
